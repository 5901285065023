import { RouteNames } from "./types";

export const RoutePaths: RouteNames = {
  ROOT: "/",
  INVITE: "/invite",
  LOGIN: "/login",
  GIT_HOST_SETUP: "/git-host/setup",
  PREVIEWS: "/workspace/:workspaceId/previews",
  PREVIEW: "/workspace/:workspaceId/previews/:previewId",
  MEMBERS: "/workspace/:workspaceId/members",
  SETTINGS: "/workspace/:workspaceId/settings",
  SLACK_INTEGRATION: "/workspace/:workspaceId/settings/slack-integration",
};

export class QuillRoutes {
  static getRoot() {
    return RoutePaths.ROOT;
  }

  static getInvite() {
    return RoutePaths.INVITE;
  }

  static getLogin() {
    return RoutePaths.LOGIN;
  }

  static getGitHostSetUp() {
    return RoutePaths.GIT_HOST_SETUP;
  }

  static getPreviews(workspaceId: string) {
    return RoutePaths.PREVIEWS.replace(":workspaceId", workspaceId);
  }

  static getPreview(workspaceId: string, previewId: string) {
    return RoutePaths.PREVIEW.replace(":workspaceId", workspaceId).replace(":previewId", previewId);
  }

  static getMembers(workspaceId: string) {
    return RoutePaths.MEMBERS.replace(":workspaceId", workspaceId);
  }

  static getSettings(workspaceId: string) {
    return RoutePaths.SETTINGS.replace(":workspaceId", workspaceId);
  }

  static getSlackIntegration(workspaceId: string) {
    return RoutePaths.SLACK_INTEGRATION.replace(":workspaceId", workspaceId);
  }
}
