import { createContext, useEffect, useState } from "react";
import { User, onAuthStateChanged } from "firebase/auth";

import { AuthContextValues, GitHost } from "../features/auth/types";
import { auth } from "config/firebase";

const defaultCtxValues = {
  signedInUser: null,
  isQuillLoggedIn: false,
  gitHost: GitHost.unknown,
  authService: null,
};

export const AuthContext = createContext<AuthContextValues>(defaultCtxValues);

export function AuthProvider({ children }: any) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [checkingAuthState, setCheckingAuthState] = useState(false);
  const [signedInUser, setSignedInUser] = useState<User | null>(null);

  useEffect(() => {
    setCheckingAuthState(true);
    onAuthStateChanged(auth, (user) => {
      setCheckingAuthState(false);
      setIsLoggedIn(!!user);
    });

    setSignedInUser(auth.currentUser);
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider
      value={{
        signedInUser,
        checkingAuthState,
        isQuillLoggedIn: isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
