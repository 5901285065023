import { InvitationStatus } from "features/members/types";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export class QuillEndpoints {
  static registerUser() {
    return `${BACKEND_URL}/user/register`;
  }

  static workspaces() {
    return `${BACKEND_URL}/workspace`;
  }

  static workspace(workspaceId: string) {
    return `${BACKEND_URL}/workspace/${workspaceId}`;
  }

  static workspaceApiKey(workspaceId: string) {
    return `${BACKEND_URL}/workspace/${workspaceId}/api-key`;
  }

  static updateInvite(workspaceId: string, inviteCode: string) {
    return `${BACKEND_URL}/workspace/${workspaceId}/invites?invite_code=${inviteCode}`;
  }

  static getGitHubAppToken() {
    return `${BACKEND_URL}/auth/github/app/token`;
  }

  static invite(workspaceId: string) {
    return `${BACKEND_URL}/workspace/${workspaceId}/invite`;
  }

  static invites(workspaceId: string, status: InvitationStatus) {
    return `${BACKEND_URL}/workspace/${workspaceId}/invites?status=${status}`;
  }

  static members(workspaceId: string) {
    return `${BACKEND_URL}/workspace/${workspaceId}/members`;
  }

  static preview(workspaceId: string, previewId: string) {
    return `${BACKEND_URL}/workspace/${workspaceId}/previews/${previewId}`;
  }

  static previews(workspaceId: string) {
    return `${BACKEND_URL}/workspace/${workspaceId}/previews`;
  }

  static authenticateSlack() {
    return `${BACKEND_URL}/auth/oauth/slack`;
  }
}
